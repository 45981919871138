<template>
  <div class="section">
    <h3 class="subject">町田啓太課程表</h3>
    <div class="list">
      <ul class="banner-list">
        <li>
          <!-- TODO: banner -->
          <a href="https://pse.is/3nqsfj" target="_blank">
            <img :src="require('@/assets/images/BN_superrich.png')" alt="">
          </a>
        </li>
        <li>
          <!-- TODO: banner -->
          <img :src="require('@/assets/images/BN_bluesky.png')" alt="">
        </li>
        <li>
          <!-- TODO: banner -->
          <a href="https://pse.is/3mtg3r" target="_blank">
            <img :src="require('@/assets/images/BN_jam.png')" alt="">
          </a>
        </li>
        <li>
          <!-- TODO: banner -->
          <a href="https://pse.is/3plhsh" target="_blank">
            <img :src="require('@/assets/images/BN_hana.png')" alt="">
          </a>
        </li>
      </ul>
      <ul class="card-list card">
        <li class="item" v-for="(item, idx) in course" :key="idx">
          <p>{{ item.title }}</p>
          <div class="tags">
            <span class="tag" :class="tag.classes" v-for="tag in item.label" :key="tag.name">
              <a :href="tag.link" target="_blank">
              {{ tag.name }}
              </a>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Idol',
  computed: {
    course () {
      return [
        {
          title: 'Love or Not 愛不愛由你',
          label: [
            {
              name: 'KKTV',
              classes: 'brown',
              link: 'https://hi.kktv.to/3prwy7'
            }
          ]

        },
        {
          title: '謊言開始的戀愛',
          label: [
            {
              name: 'friDay 影音',
              classes: 'blue',
              link: 'https://pse.is/3ndz43'
            }
          ]

        },
        {
          title: '前田建設奇幻營業部',
          label: [
            {
              name: 'friDay 影音',
              classes: 'blue',
              link: 'https://pse.is/3prgxh'
            }
          ]

        },
        {
          title: '西荻窪 三星洋酒堂',
          label: [
            {
              name: 'friDay 影音',
              classes: 'blue',
              link: 'https://pse.is/3pjkn4'
            }
          ]

        },
        {
          title: '今際之國的闖關者',
          label: [
            {
              name: 'NETFLIX',
              classes: 'red',
              link: 'https://pse.is/3pzpup'
            }
          ]

        },
        {
          title: '如果30歲還是處男似乎就會變成魔法師',
          label: [
            {
              name: 'KKTV',
              classes: 'brown',
              link: 'https://hi.kktv.to/3mlxhl'
            }
          ]

        },
        {
          title: 'Guilty這個戀愛有罪嗎？',
          label: [
            {
              name: 'KKTV',
              classes: 'brown',
              link: 'https://hi.kktv.to/3pkgjw'
            },
            {
              name: 'friDay 影音',
              classes: 'blue',
              link: 'https://pse.is/3l5l67'
            }
          ]

        },
        {
          title: '偷窺情人',
          label: [
            {
              name: 'KKTV',
              classes: 'brown',
              link: 'https://hi.kktv.to/3qbjxq'
            },
            {
              name: 'friDay 影音',
              classes: 'blue',
              link: 'https://pse.is/3q7xhh'
            }
          ]
        },
        {
          title: '二階堂物語',
          label: [
            {
              name: 'friDay 影音',
              classes: 'blue',
              link: 'https://pse.is/3m3unh'
            }
          ]
        },
        {
          title: '熱血極速',
          label: [
            {
              name: 'KKTV',
              classes: 'brown',
              link: 'https://hi.kktv.to/3m2b9l'
            },
            {
              name: 'friDay 影音',
              classes: 'blue',
              link: 'https://pse.is/3payb6'
            }
          ]
        },
        {
          title: 'HiGH＆LOW熱血街頭電影版2：天空的盡頭',
          label: [
            {
              name: 'friDay 影音',
              classes: 'blue',
              link: 'https://pse.is/3p29xr'
            }
          ]

        },
        {
          title: 'HiGH＆LOW熱血街頭電影版3：終極任務',
          label: [
            {
              name: 'friDay 影音',
              classes: 'blue',
              link: 'https://pse.is/3ncvh5'
            }

          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.card-list .item {
  column-gap: 55px;
}
</style>
