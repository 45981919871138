<template>
  <div class="section">
    <h3 class="subject">秋季日劇課程表</h3>
    <div class="list">
      <ul class="banner-list">
        <li>
          <!-- TODO: banner -->
          <a href="https://pse.is/3nqsfj" target="_blank">
          <img :src="require('@/assets/images/BN_superrich.png')" alt="">
          </a>
        </li>
      </ul>
      <ul class="card-list">
        <template v-for="(items, idx) in course">
          <li v-if="idx === course.length - 2" :key="`banner-${idx}`">
            <!-- TODO: banner -->
            <img :src="require('@/assets/images/BN_bluesky.png')" alt="">
          </li>
          <li class="card" :class="`card-${idx}`" :key="idx">
            <div class="weekday">
              <span>{{ tranformDate(idx) }}</span>
            </div>
            <div class="info">
              <div class="item" v-for="(item) in items" :key="item.title">
                <p>{{ item.title }}</p>
                <div class="tags">
                  <span class="tag" :class="tag.classes" v-for="tag in item.label" :key="tag.name">
                    <a :href="tag.link" target="_blank">
                    {{ tag.name }}
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <template v-if="idx === course.length - 1"></template>

          </li>
          <li v-if="idx === course.length - 1" :key="`banner-${idx}`">
            <!-- TODO: banner -->
            <a href="https://pse.is/3plhsh" target="_blank">
              <img :src="require('@/assets/images/BN_hana.png')" alt="">
            </a>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Drama',
  computed: {
    course () {
      return [
        [
          {
            title: '日本沉沒ー希望之人ー',
            label: [
              {
                name: 'KKTV',
                classes: 'brown',
                link: 'https://hi.kktv.to/3n8b4f'
              },
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3ltack'
              },
              {
                name: 'NETFLIX',
                classes: 'red',
                link: 'https://pse.is/3qlq6n'
              }
            ]
          },
          {
            title: '真凶標籤',
            label: [
              {
                name: 'KKTV',
                classes: 'brown',
                link: 'https://hi.kktv.to/3q5csl'
              }
            ]
          }
        ],
        [
          {
            title: 'X光室的奇蹟2',
            label: [
              {
                name: 'KKTV',
                classes: 'brown',
                link: 'https://hi.kktv.to/3lxu8r'
              },
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3q4kyk'
              }
            ]
          },
          {
            title: 'Avalanche雪崩',
            label: [
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3pfqws'
              }
            ]
          }
        ],
        [
          {
            title: '只是在結婚申請書上蓋個章而已',
            label: [
              {
                name: 'KKTV',
                classes: 'brown',
                link: 'https://hi.kktv.to/3q8pp3'
              },
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3qq6ln'
              }
            ]
          }
        ],
        [
          {
            title: '不良少年與白手杖女孩',
            label: [
              {
                name: 'KKTV',
                classes: 'brown',
                link: 'https://hi.kktv.to/3pskl3'
              },
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3q3fsq'
              }
            ]
          }
        ],
        [
          {
            title: '反正你也逃不掉',
            label: [
              {
                name: 'KKTV',
                classes: 'brown',
                link: 'https://hi.kktv.to/3pchj8'
              }
            ]
          },
          {
            title: '派遣女醫X',
            label: [
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3nsf8v'
              }
            ]
          },
          {
            title: '螺旋迷宮',
            label: [
              {
                name: 'KKTV',
                classes: 'brown',
                link: 'https://hi.kktv.to/3qahhz'
              }
            ]
          },
          {
            title: '幸運少女組',
            label: [
              {
                name: 'KKTV',
                classes: 'brown',
                link: 'https://hi.kktv.to/3p8ksu'
              }
            ]
          },
          {
            title: '被擦掉的初戀',
            label: [
              {
                name: 'NETFLIX',
                classes: 'red',
                link: 'https://pse.is/3qgb3y'
              }
            ]
          }
        ],
        [
          {
            title: '最愛',
            label: [
              {
                name: 'KKTV',
                classes: 'brown',
                link: 'https://hi.kktv.to/3p36vx'
              },
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3p659c'
              }
            ]
          },
          {
            title: '性感男友讓我著迷',
            label: [
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3p5mz9'
              }
            ]
          },
          {
            title: '和田家的男人們',
            label: [
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3pfqws'
              }
            ]
          },
          {
            title: '前科者~新進保護司・阿川佳代',
            label: [
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3pfqws'
              }
            ]
          }
        ],
        [
          {
            title: '二月的勝者─絕對合格教室─',
            label: [
              {
                name: 'KKTV',
                classes: 'brown',
                link: 'https://hi.kktv.to/3pqh46'
              }
            ]
          },
          {
            title: '言靈莊',
            label: [
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3jws6y'
              }
            ]
          },
          {
            title: '所羅門的偽證',
            label: [
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3pz72t'
              }
            ]
          },
          {
            title: '異邦人',
            label: [
              {
                name: 'friDay 影音',
                classes: 'blue',
                link: 'https://pse.is/3pfqws'
              }
            ]
          }
        ]
      ]
    }
  },
  methods: {
    tranformDate (idx) {
      const weekday = ['週一', '週二', '週三', '週四', '週五', '週六', '週日']
      return weekday[idx]
    }
  }
}
</script>

<style scoped>
.card-list {
  @apply grid;
  row-gap: 10px;
}
.card-list .card {
  @apply flex items-stretch;
}
.card-list .info {
  @apply flex-grow;
}
.card-list .item {
  column-gap: 23px;
}
.weekday {
  @apply font-bold text-white flex items-center justify-center;;
  flex: 0 0 50px;
}
.card.card-0 .weekday {
  background-color: #519171;
}
.card.card-1 .weekday {
  background-color: #E85F25;
}
.card.card-2 .weekday {
  background-color: #D2763A;
}
.card.card-3 .weekday {
  background-color: #BB8457;
}
.card.card-4 .weekday {
  background-color: #C19626;
}
.card.card-5 .weekday {
  background-color: #96AD08;
}
.card.card-6 .weekday {
  background-color: #36A69F;
}
</style>
