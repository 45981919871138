<template>
  <div class="section">
    <h3 class="subject">劇團EXILE課程表</h3>
    <ul class="banner-list">
      <li>
        <!-- TODO: banner -->
        <a href="https://pse.is/3mtg3r" target="_blank">
          <img :src="require('@/assets/images/BN_jam.png')" alt="">
        </a>
    </li>
      <li>
        <!-- TODO: banner -->
        <img :src="require('@/assets/images/BN_jamthedrama.png')" alt="">
      </li>
      <li>
        <!-- TODO: banner -->
        <a href="https://pse.is/3p29xr" target="_blank">
          <img :src="require('@/assets/images/BN_highlow2.png')" alt="">
        </a>
      </li>
      <li>
        <!-- TODO: banner -->
        <a href="https://pse.is/3ncvh5" target="_blank">
          <img :src="require('@/assets/images/BN_highlow3.png')" alt="">
        </a>
      </li>
      <li>
        <!-- TODO: banner -->
        <a href="https://pse.is/3pzpup" target="_blank">
          <img :src="require('@/assets/images/BN_netflix.png')" alt="">
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Exile',
  computed: {
    course () {
      return [
      ]
    }
  }
}
</script>
