<template>
  <div class="classroom">
    <h3 class="subject">{{ title }}</h3>
    <div class="seats relative">
      <template v-for="(detail, idx) in display_list">
        <Person class="absolute" :key="idx" :detail="detail" :animal="animal[idx]" />
      </template>
    </div>
    <div class="action">
      <button class="btn" @click="pageReload">老師我要換座位 ಠ_ಠ</button>
      <a class="btn text-center" target="_blank" :href="'http://www.facebook.com/sharer.php?u='+getURL()">分享到 Facebook</a >
    </div>
  </div>
</template>
<script>
import Person from './Person.vue'
export default {
  name: 'classroom',
  props: {
    theme: {
      type: String,
      default: 'default'
    },
    userName: {
      type: String,
      default: 'Name'
    }
  },
  components: {
    Person
  },
  data () {
    return {
      // list 要放隨機的名字和動物組合
      origin_list: [],
      display_list: [],
      user_data: {
        name: 'sun'
      },
      animal: [
        'bird',
        'cat',
        'cat_b',
        'cattle',
        'dog',
        'dog_y',
        'goat',
        'monkey',
        'mouse',
        'pig',
        'rabbit',
        'rabbit_b',
        'rooster'
      ]
    }
  },
  computed: {
    title () {
      switch (this.theme) {
        case 'drama':
          return '妳的日劇秋季班座位表'
        case 'exile':
          return '妳的劇團EXILE 座位表'
        case 'default':
        default:
          return '妳的町田啓太座位表'
      }
    }
  },
  created () {
    this.user_data.name = this.userName
  },
  mounted () {
    this.themeArray()
    this.randAnimalSor()
    this.getQuery()
  },
  methods: {
    randAnimalSor () {
      this.animal = this.sort(this.animal)
    },
    pageReload () {
      window.scrollTo({
        top: 0,
        left: 0
      })
      this.$emit('templ', 'Home')
    },
    getURL () {
      return 'https://' + window.location.hostname
    },
    themeArray () {
      switch (this.theme) {
        default:
        case 'home':
          this.origin_list = {
            requireItems: [
              {
                name: '町田啓太'
              },
              {
                name: '宮村空'
              },
              {
                name: '佐野亘'
              },
              {
                name: '平作'
              },
              {
                name: '和泉浩司'
              },
              {
                name: '原田登'
              },
              {
                name: '北川俊介'
              },
              {
                name: '增田順平'
              },
              {
                name: '多田洋輔'
              },
              {
                name: '結城理一'
              },
              {
                name: '山田純平'
              },
              {
                name: '佐久間恭介'
              },
              {
                name: '苅部大吉'
              },
              {
                name: '向坂遼'
              },
              {
                name: '後藤忠臣'
              },
              {
                name: '川合勝太郎'
              },
              {
                name: '雨宮涼一朗'
              },
              {
                name: '土方歲三'
              },
              {
                name: '鴨下淳之介'
              },
              {
                name: '村岡郁彌'
              },
              {
                name: '西野タケル'
              },
              {
                name: '秋山慶一'
              },
              {
                name: '丸尾拓馬'
              },
              {
                name: 'ワセダ'
              },
              {
                name: '久我山草樹'
              },
              {
                name: '谷遼平'
              },
              {
                name: '溝口洋介'
              },
              {
                name: '渡久地誠二'
              }
            ],
            optionalItems: [

            ]
          }

          break
        case 'exile':
          this.origin_list = {
            requireItems: [
              {
                name: '青柳翔'
              },
              {
                name: '秋山真太郎'
              },
              {
                name: '小澤雄太'
              },
              {
                name: '鈴木伸之'
              },
              {
                name: '小野塚勇人'
              },
              {
                name: 'Sway'
              },
              {
                name: '八木将康'
              },
              {
                name: '佐藤寬太'
              },
              {
                name: '町田啓太'
              },
              {
                name: ''
              },
              {
                name: ''
              },
              {
                name: ''
              }

            ],
            optionalItems: []
          }
          break
        case 'drama':
          this.origin_list = {
            requireItems: [
              {
                name: '町田啓太'
              },
              {
                name: '赤楚衛二'
              },
              {
                name: '吉澤亮'
              },
              {
                name: '田中圭'
              },
              {
                name: '千葉雄大'
              },
              {
                name: '窪田正孝'
              },
              {
                name: '相葉雅紀'
              },
              {
                name: '菅田將暉'
              }
            ],
            optionalItems: [
              {
                name: '小栗旬'
              },
              {
                name: '綾野剛'
              },
              {
                name: '福士蒼汰'
              },
              {
                name: '鈴木伸之'
              },
              {
                name: '坂口健太郎'
              },
              {
                name: '杉野遙亮'
              },
              {
                name: '松山研一'
              },
              {
                name: '白洲迅'
              },
              {
                name: '柳樂優彌'
              },
              {
                name: '加藤成亮'
              },
              {
                name: '永山絢斗 '
              },
              {
                name: '西島秀俊'
              },
              {
                name: '道枝駿佑 '
              },
              {
                name: '目黑蓮'
              }
            ]
          }
          break
      }
    },
    getQuery: function () {
      const { code } = this.$route.query
      if (code) {
        this.showList(JSON.parse(this.atou(code)))
        // this.showList(JSON.parse(window.atob(decodeURIComponent(code).replaceAll(' ', '+'))))
      } else {
        this.randList()
      }
    },
    atou: function (code) {
      return JSON.parse(decodeURIComponent(escape(atob(
        code
          .replace(/-| /g, '+')
          .replace(/_/g, '/')
          .replace(/%/g, '%25')
      ))))
    },
    utoa: function (Object) {
      return window.btoa(unescape(encodeURIComponent(JSON.stringify(Object))))
    },

    randList: function () {
      const readyList = JSON.parse(JSON.stringify(this.origin_list))
      const requireItems = this.sort(readyList.requireItems)
      const optionalItems = this.sort(readyList.optionalItems).splice(0, 7)
      const finalItems = this.sort(requireItems.concat(optionalItems).splice(0, 12).concat(this.user_data))
      const objJsonStr = JSON.stringify(finalItems)
      const objJsonB64 = this.utoa(objJsonStr)
      // const objJsonB64 = Buffer.from(objJsonStr).toString('base64')
      this.$router.push({ query: { code: objJsonB64 } })
      this.showList(finalItems)
    },
    showList: function (list) {
      this.display_list = list
    },
    sort: function (array) {
      return array.sort(() => Math.random() - 0.5)
    }

  }
}
</script>

<style scoped>
.classroom {
  padding: 0 8px;
  margin: 22px auto 40px;
}
.seats {
  width: 100%;
  height: 542px;
  background: url('../assets/images/bg_class.png') 0 0 no-repeat;
  background-size: contain;
}
::v-deep .person:first-child {
  top: 46px;
  left: 165px;
}
::v-deep .person:nth-child(2),
::v-deep .person:nth-child(3),
::v-deep .person:nth-child(4) {
  top: 142px;
}
::v-deep .person:nth-child(5),
::v-deep .person:nth-child(6),
::v-deep .person:nth-child(7) {
  top: 230px;
}
::v-deep .person:nth-child(8),
::v-deep .person:nth-child(9),
::v-deep .person:nth-child(10) {
  top: 310px;
}
::v-deep .person:nth-child(11),
::v-deep .person:nth-child(12),
::v-deep .person:nth-child(13) {
  top: 390px;
}
::v-deep .person:nth-child(2),
::v-deep .person:nth-child(8) {
  left: 61px;
}
::v-deep .person:nth-child(5),
::v-deep .person:nth-child(11) {
  left: 30px;
}
::v-deep .person:nth-child(3),
::v-deep .person:nth-child(12) {
  left: 160px;
}
::v-deep .person:nth-child(6),
::v-deep .person:nth-child(9) {
  left: 190px;
}
::v-deep .person:nth-child(4) {
  left: 305px;
}
::v-deep .person:nth-child(7),
::v-deep .person:nth-child(13) {
  left: 285px;
}
::v-deep .person:nth-child(10) {
  left: 315px;
}
.action {
  @apply m-auto grid;
  width: 300px;
  margin-top: 34px;
  row-gap: 22px;
}
.action .btn:last-child {
  background-color: var(--button-blue-bg);
}
</style>
