<template>
  <div class="wrapper">
    <div class="wrapper-top">
      <div class="content">
        <p v-for="(item, name) in description" :key="name">
          {{ item }}
        </p>
      </div>
      <div class="form">
        <input class="input" v-model="name" type="text" placeholder="輸入名字，不能超過５個字喔">
        <div class="action">
          <button class="btn" :disabled="name.length < 1 || name.length > 5" type="submit" @click="onSubmit">新學期妳的命運！</button>
          <span class="text-alert" v-show="name.length > 5">噗噗～名字不能比{{ limitName }}長喔 (⑉･̆⌓･̆⑉)</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      name: ''
    }
  },
  computed: {
    theme () {
      let theme = 'default'
      let name = this.$route.name
      name = name.toLowerCase()
      switch (name) {
        case 'home':
          theme = 'default'
          break
        default:
          theme = name
          break
      }
      return theme
    },
    description () {
      switch (this.theme) {
        case 'drama':
          return {
            subTitle: '一轉眼就到秋季日劇開播的時刻啦～',
            description: '準備好進入日劇秋季班跟大家一起學習新日劇了嗎？快來看看這學期妳的座位在哪裡 ヽ(^0^)ﾉ',
            notice: '※本班只有秋季日劇的男同學沒有女同學喔'
          }
        case 'exile':
          return {
            subTitle: '劇團EXILE 買一送八！',
            description: '不管是劇團新同學還是舊同學，快來看看新的學期妳跟誰坐在一起ヽ(^0^)ﾉ'
          }
        case 'default':
        default:
          return {
            subTitle: '又到了秋季班開學的時刻啦٩(^ᴗ^)۶',
            description: '這學期的日劇班也開了新的町田啓太課程「SUPER RICH」，快來看看這學期妳的座位在哪裡ヽ(^0^)ﾉ'
          }
      }
    },
    limitName () {
      switch (this.theme) {
        case 'drama':
          return '坂口健太郎'
        case 'exile':
          return '秋山真太郎'
        case 'default':
        default:
          return '佐久間恭介'
      }
    }
  },
  methods: {
    onSubmit () {
      this.$emit('userName', this.name)
      this.$emit('update:templ', 'Class')
    }
  }
}
</script>

<style scoped>
.wrapper {
  padding: 0 8px;
}
.wrapper-top {
  background: url('../assets/images/bg_down.png') bottom center no-repeat;
  background-size: contain;
  padding-bottom: 110px;
  margin-bottom: 40px;
}
.content {
  @apply font-bold text-center leading-6;
  text-shadow: 2px 2px 0 var(--color-white), -2px -2px 0 var(--color-white), 1px 1px 0 var(--color-white), -1px -1px 0 var(--color-white);
}
.form {
  @apply text-center grid;
  width: 300px;
  margin: 20px auto 0;
  row-gap: 20px;
}
.action {
  @apply grid;
  row-gap: 9px;
}
</style>
