<template>
  <div>
    <Classroom :theme="theme" :userName.sync="userName" @templ="(val) => {$emit('update:templ', val);$router.push('/')}" />
    <component :is="templ" />
  </div>
</template>
<script>
import Classroom from './Classroom.vue'
import Idol from './Idol.vue'
import Exile from './Exile.vue'
import Drama from './Drama.vue'
export default {
  name: 'class',
  props: {
    userName: {
      type: String,
      default: ''
    }
  },
  components: {
    Classroom,
    Idol,
    Exile,
    Drama
  },
  computed: {
    theme () {
      let theme = 'default'
      let name = this.$route.name
      name = name.toLowerCase()
      switch (name) {
        case 'home':
          theme = 'default'
          break
        default:
          theme = name
          break
      }
      return theme
    },
    templ () {
      switch (this.$route.name) {
        case 'Home':
          return 'Idol'
        default:
          return this.$route.name
      }
    }
  }
}
</script>
<style scoped>
::v-deep .subject {
  @apply font-semibold text-xl text-white text-center;
  background-color: var(--color-darker);
  padding: 8px 0;
}
::v-deep .list,
::v-deep .banner-list {
  @apply grid;
  row-gap: 10px;
}
::v-deep .banner-list {
  padding: 20px 8px 0;
}
::v-deep .card {
  @apply bg-white overflow-hidden;
  border-radius: 10px;
}
::v-deep .card-list {
  padding: 5px 0;
}
::v-deep .card-list .item {
  @apply grid items-center;
  grid-template-columns: 1fr 100px;
  padding: 15px 16px;
}
::v-deep .card-list .item:not(:last-child) {
  border-bottom: 1px dashed var(--border-dash-color);
}
::v-deep .tags {
  @apply flex flex-col justify-between;
  row-gap: 12px;
}
</style>
