<template>
  <component :is="templ" :templ.sync="templ" :userName="userName" @userName="(val) => userName = val" />
</template>

<script>
import Home from '@/components/Home'
import Class from '@/components/Class'
export default {
  name: 'Index',
  props: {
    theme: {
      type: String,
      default: 'default'
    }
  },
  components: {
    Home,
    Class
  },
  data () {
    return {
      userName: '',
      templ: 'Home'
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.templ = 'Home'
      if (to.query?.code) {
        vm.templ = 'Class'
      }
    })
  },
  watch: {
    $route (to) {
      this.templ = 'Home'
      if (to.query?.code) {
        this.templ = 'Class'
      }
    }
  }
}
</script>
