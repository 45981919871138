<template>
  <div class="person">
    <p class="name" v-if="detail.name.length">{{detail.name}}</p>
    <img :src="require('@/assets/images/animal/'+animal+'.png')"  v-if="detail.name.length">
  </div>
</template>
<script>
export default {
  name: 'person',
  props: {
    detail: {
      type: Object,
      default: () => {},
      require: true
    },
    animal: {
      type: String,
      default: () => {},
      require: true
    }

  }
}
</script>

<style scoped>
.person {
  line-height: 1;
}
.person .name {
  @apply text-white;
  text-shadow: 1px 1px 0 var(--color-black), -1px -1px 0 var(--color-black), 1px -1px 0 var(--color-black), -1px 1px 0 var(--color-black), 2px 2px 0 var(--color-black), -2px -2px 0 var(--color-black);
  transform: scale(0.73);
  transform-origin: 0 0;
}
.person .name:before {
  @apply align-middle rounded-full inline-block;
  width: 6px;
  height: 6px;
  background-color: #FF6500;
  border: 1px solid var(--color-black);
  margin-right: 5px;
  content: '';
  transform: scale(1.37);
}
.person img {
  width: 40px;
  height: 40px;
}
</style>
